import { SET_SOLUTION, SET_OPEN_CODE_EDITOR, SET_TOAST } from "./types";

export const setSolution = (solution, articleId) => ({
  type: SET_SOLUTION,
  payload: { solution, articleId }
});

export const setOpenCodeEditor = open => ({
  type: SET_OPEN_CODE_EDITOR,
  payload: { open }
});

export const setToast = toast => ({
  type: SET_TOAST,
  payload: { toast }
});
