import React, { useState, useEffect, useCallback } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Spinner, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import Modal from "../Modal";
import LoginCard from "../LoginCard";
import {
  Container,
  TestResults,
  Console,
  ResultsContainer,
  StyledRow
} from "./components";
import useAccountStatus from "../../hooks/useAccountStatus";
import useCurrentArticle from "../../hooks/useCurrentArticle";
import useActions from "../../hooks/useActions";

require("codemirror/lib/codemirror.css");
require("codemirror/theme/material.css");
require("codemirror/theme/neat.css");
require("codemirror/mode/javascript/javascript.js");

const CodeEditor = () => {
  const { isLoggedIn } = useAccountStatus();
  const { articleId, defaultCode } = useCurrentArticle();
  const { postSolution, setSolution } = useActions();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const solution = useSelector(state => state.active?.solution?.[articleId]);
  const [testResults, setTestResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!solution) {
      setSolution(defaultCode, articleId);
    }
    setTestResults([]);
  }, [articleId, defaultCode, setSolution, solution]);

  const onReset = () => {
    setSolution(defaultCode, articleId);
    setTestResults([]);
  };

  const handleRun = useCallback(async () => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
    } else {
      setLoading(true);
      try {
        const response = await postSolution({ articleId, solution });
        setTestResults(response);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  }, [
    setLoading,
    setTestResults,
    articleId,
    solution,
    isLoggedIn,
    postSolution
  ]);

  return (
    <Container>
      <CodeMirror
        value={solution}
        options={{
          mode: "javascript",
          theme: "material",
          lineNumbers: true
        }}
        onBeforeChange={(editor, data, value) => {
          setSolution(value, articleId);
        }}
        onChange={(editor, data, value) => {}}
      />
      <Console>
        <StyledRow>
          <Button onClick={handleRun} variant="light">
            Run Code!
          </Button>
          <Button onClick={onReset} variant="light">
            Reset
          </Button>
        </StyledRow>
        <ResultsContainer>
          {loading ? (
            <Spinner animation="grow" variant="primary" size="large" />
          ) : (
            <TestResults testResults={testResults} />
          )}
        </ResultsContainer>
      </Console>
      <Modal
        show={showLoginModal}
        setShow={setShowLoginModal}
        title="Log in/Sign up"
      >
        <LoginCard />
      </Modal>
    </Container>
  );
};

export default CodeEditor;
