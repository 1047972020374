import { combineReducers } from "redux";

import languageId from "./languageId";
import solution from "./solution";
import openCodeEditor from "./openCodeEditor";
import toast from "./toast";

export default combineReducers({
  languageId,
  solution,
  openCodeEditor,
  toast
});
