import { useMemo } from "react";
import * as actions from "../redux/actions";
import { useDispatch } from "react-redux";

const useActions = () => {
  const dispatch = useDispatch();

  // Memoize it to avoid recreating it every time
  const connected = useMemo(() => {
    const connected = {};
    for (let key in actions) {
      const action = actions[key];
      if (action && typeof action === "function") {
        connected[key] = (...args) => dispatch(action(...args));
      }
    }
    return connected;
  }, [dispatch]);

  return connected;
};

export default useActions;
