import { SET_ACCOUNT } from "../actions/types";

const account = (state = null, { type, payload }) => {
  if (type === SET_ACCOUNT) {
    if (!payload?.account) {
      return null;
    }

    return { ...state, ...payload.account };
  }

  return state;
};

export default account;
