import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { getArticles } from "../redux/actions/articles";
import { Row } from "react-bootstrap";
import { primary } from "../constants/colors";

const ItemContainer = styled.div`
  background-color: #f7f7f7;
  padding: 15px;
  height: 170px;
  margin: 0 15px 15px 15px;
  width: calc(50% - 30px);
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;

  * > {
    margin-bottom: 16px;
  }

  :hover {
    box-shadow: 0 0 10px 0 #dcdcdc;
  }
`;

const Title = styled.h3`
  font-size: 1.3rem;
  display: inline;
  vertical-align: middle;
  line-height: 1.5;
`;

const Tag = styled.div`
  padding: 0.3em 1em 0.3em 0.5em;
  display: inline-block;
  background: ${primary};
  vertical-align: middle;
  border-radius: 25px 5px 5px 25px;
  height: 34px;

  :before {
    content: "●";
    color: white;
    margin-right: 8px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QuestionItem = ({ title, description, onClick }) => {
  return (
    <ItemContainer onClick={onClick} tag="Free">
      <TitleContainer>
        <Title>{title}</Title>
        <Tag>Free</Tag>
      </TitleContainer>
      <div>{description}</div>
    </ItemContainer>
  );
};

const CategoryItemContainer = styled.div`
  > * {
    margin-bottom: 20px;
  }
`;

const CategoryItem = ({ name, articles, onClick, index }) => {
  return (
    <CategoryItemContainer>
      <h2>{index + ". " + name}</h2>
      <Row>
        {articles.map(q => (
          <QuestionItem
            title={q.title}
            description={q.description}
            key={q.id}
            onClick={onClick(q.id)}
          />
        ))}
      </Row>
    </CategoryItemContainer>
  );
};

const configureCategories = state => {
  const categories = state.categories || [];
  const articles = state.articles;

  return Object.entries(categories)
    .map(([id, category]) => {
      const categoryarticles = Object.values(articles).filter(
        q => q?.categoryId === id
      );

      return {
        id,
        name: category.name,
        articles: categoryarticles
      };
    })
    .filter(category => category.articles.length > 0);
};

const CategoriesContainer = styled.div`
  > * {
    margin-bottom: 30px;
  }
`;

const Articles = () => {
  const dispatch = useDispatch();
  const categories = useSelector(configureCategories);
  const history = useHistory();
  const onClick = id => () => {
    history.push("/articles/" + id.toString());
  };

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  return (
    <CategoriesContainer>
      <h1>This is Articles page!</h1>
      {categories.map((category, index) => (
        <CategoryItem
          {...category}
          key={category.id}
          onClick={onClick}
          index={index}
        />
      ))}
    </CategoriesContainer>
  );
};

export default Articles;
