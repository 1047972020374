import React from "react";
import { useSelector } from "react-redux";

import firebase from "../../utils/firebase";
import {
  PriceItem,
  HeadContainer,
  HeadDescription,
  PriceListContainer,
  Container
} from "./components";
import Quote from "../../components/Quote";
import kenta from "../../assets/images/kenta.jpg";

const skus = {
  sku_Gn6W4qfRHne0Vx: {
    title: "Temporary Access",
    price: "¥6,000",
    description: "急ぎで面接対策したい方におすすめ",
    points: ["3週間の期間限定アクセス", "コミュニティーへの永久アクセス"]
  },
  temp_id: {
    title: "フルアクセス + コーチング",
    price: "¥14,800",
    description: "しっかり対策したい方におすすめ",
    points: [
      "永久アクセス権",
      "プロフェッショナルのエンジニアと1時間オンライン面談",
      "コミュニティーへの永久アクセス"
    ]
  }
};

const Subscription = () => {
  const stripe = window.Stripe("pk_test_HyBbpVfz7G42d10wYt0AKEml00kstssdrH");
  const account = useSelector(state => state.account);
  const currentUser = firebase.auth().currentUser;

  const onClick = sku => () => {
    stripe.redirectToCheckout({
      items: [{ sku, quantity: 1 }],
      successUrl: "https://code-breakfast.com/",
      cancelUrl: "https://code-breakfast.com/",
      clientReferenceId: account?.id,
      customerEmail: currentUser?.email
    });
  };

  return (
    <Container>
      <HeadContainer>
        <h1>
          コーディング面接をマスターして
          <br />
          夢の職場で働こう！
        </h1>
        <HeadDescription>
          満足頂けなければ、<strong>7日以内全額返金</strong>します。
        </HeadDescription>
      </HeadContainer>

      <PriceListContainer>
        {Object.entries(skus).map(([key, value]) => (
          <PriceItem {...value} onClick={onClick(key)} key={key} />
        ))}
      </PriceListContainer>

      <Quote
        name="Kenta"
        company="Hoge"
        message="I can't believe how this problem set covered such a wide variety of algorithmic approaches in detail. It transformed the way I think about interviews and gave me immense confidence."
        img={kenta}
      />
    </Container>
  );
};

export default Subscription;
