import { combineReducers } from "redux";
import articles from "./articles";
import sections from "./sections";
import account from "./account";
import categories from "./categories";
import solutions from "./solutions";
import active from "./active";
import languages from "./languages";

const root = combineReducers({
  articles,
  sections,
  account,
  categories,
  solutions,
  languages,
  active
});

export default root;
