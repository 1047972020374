const defaultCategories = {
  0: { name: "Algorithmic thinking" },
  1: { name: "配列操作" },
  2: { name: "ハッシュ" },
  3: { name: "Greedy algorithms" },
  4: { name: "Sorting, searching, and logarithms" },
  5: { name: "Trees and graphs" },
  6: { name: "Dynamic programming and recursion" },
  7: { name: "Queues and stacks" },
  8: { name: "Linked lists" },
  9: { name: "General programming" },
  10: { name: "Bit manipulation" },
  11: { name: "Combinatorics, probability, and other math" }
};
const categories = () => {
  return defaultCategories;
};

export default categories;
