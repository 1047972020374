import { GET_SECTIONS } from "../actions/types";

const sections = (state = {}, { type, payload }) => {
  if (type.startsWith(GET_SECTIONS) && type.endsWith("SUCCESS") && payload) {
    return { ...state, [payload.articleId]: payload.sections };
  }

  return state;
};

export default sections;
