import { GET_CATEGORIES } from "./types";
import { db } from "../../utils/firebase";

export const getCategories = () => async (dispatch, state) => {
  dispatch({ type: GET_CATEGORIES + "_REQUEST" });

  try {
    const res = await db.collection("categories").get();
    const categories = res.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    dispatch({ type: GET_CATEGORIES + "_SUCCESS", payload: { categories } });
  } catch (error) {
    dispatch({ type: GET_CATEGORIES + "_FAILURE", payload: { error } });
  }
};
