import { GET_SECTIONS } from "./types";
import { db } from "../../utils/firebase";

export const getSections = articleId => async (dispatch, getState) => {
  const languageId = getState().active?.languageId;

  dispatch({
    type: GET_SECTIONS + "_REQUEST",
    payload: { articleId, languageId }
  });

  //TODO: Get this value from redux state

  try {
    const res = await db
      .collection("sections")
      .where("articleId", "==", articleId)
      .where("languages", "array-contains", languageId)
      .get();
    console.log("res", res);
    const sections = res.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: GET_SECTIONS + "_SUCCESS",
      payload: { sections, articleId }
    });
  } catch (error) {
    dispatch({ type: GET_SECTIONS + "_FAILURE", payload: { error } });
  }
};
