import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const config = {
  apiKey: "AIzaSyDXbSMN2lYhXMl3QuTtsXwpsyM0jIqPn-I",
  authDomain: "codebreakfast-eaa92.firebaseapp.com",
  databaseURL: "https://codebreakfast-eaa92.firebaseio.com",
  projectId: "codebreakfast-eaa92",
  storageBucket: "codebreakfast-eaa92.appspot.com",
  messagingSenderId: "103853868329",
  appId: "1:103853868329:web:41dfd2a1dbf25bdd286338",
  measurementId: "G-FS6E5HEZ31"
};

firebase.initializeApp(config);

export default firebase;
export const db = firebase.firestore();
