import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import media from "../../utils/media";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;

  > * {
    margin-bottom: 16px;
  }
`;

export const HeadContainer = styled.div`
  text-align: center;
`;

export const HeadDescription = styled.div`
  font-size: 24px;
`;

export const PriceListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: 40px;

  > * {
    :not(:last-child) {
      margin-right: 24px;
    }
  }

  ${media.sp`
    > * {
      :not(:last-child) {
        margin-right: 0px;
        margin-bottom: 24px;
      }
    }
  `}
`;

const PriceItemContainer = styled.div`
  border-radius: 6px;
  box-shadow: 0px 0px 22px rgba(51, 51, 51, 0.3);
  padding: 40px;
  max-width: 400px;
  > * {
    margin-bottom: 24px;
  }

  ${media.sp`
    max-width: 500px;
  `}
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const Price = styled.div`
  font-size: 32px;
`;

const PurchaseButton = styled(Button)`
  align-self: center;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  font-weight: bold;
  margin: auto;
  display: block;
`;

export const PriceItem = ({ title, points, price, description, onClick }) => {
  return (
    <PriceItemContainer>
      <div>
        <Title>{title}</Title>
        <div>{description}</div>
      </div>
      <hr />
      <Price>{price}</Price>
      <hr />

      <div>
        <ul>
          {points.map(point => (
            <li key={point}>{point}</li>
          ))}
        </ul>
      </div>
      <PurchaseButton onClick={onClick}>Purchase →</PurchaseButton>
    </PriceItemContainer>
  );
};
