import React from "react";
import styled from "styled-components";
import FA from "react-fontawesome";
import media from "../../utils/media";

const Container = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: #f7f7f7;
  flex: 1;
  padding: 24px;

  ${media.sp`
    flex-direction: column;
    align-items: center;
  `}
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 24px;
`;

const Title = styled.div`
  margin-bottom: 8px;
`;

const TextContaienr = styled.div`
  flex: 1;
`;

const renderTitle = ({ name, company }) => {
  if (!name) return null;

  let child;
  if (company) {
    child = (
      <>
        <strong>{name}</strong> got the job at <strong>{company}</strong>!
      </>
    );
  } else {
    child = <strong>{name}</strong>;
  }

  return <Title>{child}</Title>;
};

const Quote = ({ img, message, name, company, title }) => {
  return (
    <Container>
      <Image src={img} alt="user" />
      <TextContaienr>
        {renderTitle({ name, company })}
        <FA name="quote-left" style={{ marginRight: 16 }} />
        <span>{message}</span>
      </TextContaienr>
    </Container>
  );
};

export default Quote;
