import React from "react";
import styled from "styled-components";

const StyledFooter = styled.div`
  background-color: #343a40;
`;

const FooterInner = styled.div`
  padding: 64px 32px;
`;

const Corporation = styled.p`
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
`;

const Footer = () => {
  return (
    <StyledFooter>
      <FooterInner>
        <Corporation>©︎2019 yollo, Inc.</Corporation>
      </FooterInner>
    </StyledFooter>
  );
};

export default Footer;
