import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";
import icon from "../../assets/images/icon.png";

import {
  Navbar as NavbarInner,
  Nav,
  Dropdown,
  Container,
  Button
} from "react-bootstrap";
import Modal from "../Modal";
import LoginCard from "../LoginCard";
import firebase from "../../utils/firebase";
import useAccountStatus from "../../hooks/useAccountStatus";
import useActions from "../../hooks/useActions";

const Brand = styled(NavbarInner.Brand)`
  font-weight: 900;
  letter-spacing: -1px;
`;

const StyledLink = styled(Nav.Link)`
  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const StyledNavbar = styled(NavbarInner)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 76px;
`;

const Link = ({ children, to = "/" }) => {
  return (
    <LinkContainer to={to}>
      <StyledLink>{children}</StyledLink>
    </LinkContainer>
  );
};

const SubscribedDropdown = () => {
  const userName = useSelector(state => state.account?.name);
  const { setToast } = useActions();

  const onClickLogout = () => {
    firebase.auth().signOut();
    setToast("Logged Out!!");
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        {userName}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item>
          <div>
            <p>You own CodeBreakfast!</p>
            <p>Expires on March 5, 2021</p>
            <p>(does not renew automatically)</p>
          </div>
        </Dropdown.Item>
        <Dropdown.Item>
          <Button variant="dark" onClick={onClickLogout} block>
            Log Out
          </Button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const { isLoggedin, isSubscribed } = useAccountStatus();
  const onClickLogin = useCallback(() => {
    if (isLoggedin) {
      firebase.auth().signOut();
    } else {
      setShowModal(true);
    }
  }, [isLoggedin]);

  const loginText = isLoggedin ? "Logout" : "Login";
  return (
    <StyledNavbar bg="dark" expand="lg" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Brand variant="white">
            <img src={icon} alt="icon" style={{ width: 32, height: 32 }} />
            Code Breakfast
          </Brand>
        </LinkContainer>
        <NavbarInner.Toggle aria-controls="basic-navbar-nav" />
        <NavbarInner.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/articles">Articles</Link>
          </Nav>
          {isSubscribed ? (
            <Nav>
              <SubscribedDropdown />
            </Nav>
          ) : (
            <Nav>
              <Link to="/subscription">Get the Full Course</Link>
              <StyledLink onClick={onClickLogin}>{loginText}</StyledLink>
            </Nav>
          )}
        </NavbarInner.Collapse>
        <Modal show={showModal} setShow={setShowModal} title="Log in/Sign up">
          <LoginCard />
        </Modal>
      </Container>
    </StyledNavbar>
  );
};

export default Navbar;
