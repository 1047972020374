import React from "react";
import { Modal as ModalInner } from "react-bootstrap";

const Modal = ({ children, show, setShow, title }) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <ModalInner show={show} onHide={handleClose}>
        <ModalInner.Header closeButton>
          <h2>{title}</h2>
        </ModalInner.Header>
        <ModalInner.Body>{children}</ModalInner.Body>
      </ModalInner>
    </>
  );
};

export default Modal;
