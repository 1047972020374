import { POST_SOLUTION } from "../actions/types";

const solutions = (state = {}, { type, payload }) => {
  if (type.startsWith(POST_SOLUTION) && type.endsWith("SUCCESS") && payload) {
    const { articleId, solution, testResults } = payload;
    return { ...state, [articleId]: { solution, testResults } };
  }

  return state;
};

export default solutions;
