import React, { useEffect, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { useSelector } from "react-redux";

import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import CodeEditor from "./components/CodeEditor";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import Toast from "./components/Toast";
import Subscription from "./pages/Subscription";
import firebase, { db } from "./utils/firebase";
import useActions from "./hooks/useActions";
import useCurrentArticle from "./hooks/useCurrentArticle";

const Wrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  width: 100%;
  overflow: hidden;
`;

const LeftWrapper = styled.div`
  flex: 1;
  height: 100vh;
  overflow: scroll;
`;

const ContentWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  margin: auto;
  max-width: 900px;
`;

const RightWrapper = styled.div`
  width: 50%;
  margin-right: ${({ open }) => (open ? "0" : "-50%")};
  height: 100vh;
  transition: margin-right 0.5s ease-out;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;

const EditorButtonComponent = styled(Button)`
  position: fixed;
  top: 200px;
  right: 0px;
  z-index: 8000;
`;

const EditorButton = ({ onClick, open }) => {
  const { defaultCode } = useCurrentArticle();

  return defaultCode ? (
    <EditorButtonComponent variant="dark" onClick={onClick}>
      {!open && "< "}Editor{open && " >"}
    </EditorButtonComponent>
  ) : null;
};

function App() {
  const open = useSelector(state => state.active?.openCodeEditor);

  const { setOpenCodeEditor, setAccount } = useActions();

  const onClick = () => {
    setOpenCodeEditor(!open);
  };

  const setupUser = useCallback(
    async userAuth => {
      if (!userAuth) {
        setAccount(null);
        return;
      }

      const userDb = await db
        .collection("users")
        .doc(userAuth.uid)
        .get();

      setAccount({ ...userDb.data(), name: userAuth.displayName });
    },
    [setAccount]
  );

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setupUser(user);
    });
  }, [setupUser]);

  return (
    <Router>
      <Wrapper>
        <EditorButton onClick={onClick} open={open} />
        <LeftWrapper>
          <Navbar />
          <Switch>
            <ContentWrapper>
              <Route path="/" component={Home} exact />
              <Route path="/articles" component={Articles} exact />
              <Route path="/articles/:id" component={Article} exact />
              <Route path="/subscription" component={Subscription} exact />
            </ContentWrapper>
          </Switch>
          <Footer />
        </LeftWrapper>
        <RightWrapper open={open}>
          <CodeEditor />
        </RightWrapper>
        <Toast />
      </Wrapper>
    </Router>
  );
}

export default App;
