import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const useCurrentArticle = () => {
  const location = useLocation();
  const { id } = useParams();
  const articleId =
    id ||
    (location.pathname.includes("articles") &&
      location.pathname.split("/").slice(-1)[0]);
  const article = useSelector(state =>
    Object.values(state.articles)?.find(a => a?.id === articleId)
  );
  const sections = useSelector(
    state =>
      state.sections?.[articleId]?.sort((a, b) => {
        return a.order - b.order;
      }) || []
  );
  const languageId = useSelector(state => state.active?.languageId);
  const defaultCode = article?.defaultCode[languageId];

  return { articleId, article, sections, defaultCode };
};

export default useCurrentArticle;
