import { useSelector } from "react-redux";

export const STATUSES = {
  NONE: "NONE",
  LOGGED_IN: "LOGGED_IN",
  SUBSCRIBED: "SUBSCRIBED"
};

const useAccountStatus = () => {
  const account = useSelector(state => state.account);

  const isLoggedIn = Boolean(account);
  const now = new Date().getTime();
  const isSubscribed =
    account?.subscriptionExpiredAt === "indefinite"
      ? true
      : account?.subscriptionExpiredAt?.nanosecounds < now;
  const status = isSubscribed
    ? STATUSES.SUBSCRIBED
    : isLoggedIn
    ? STATUSES.LOGGED_IN
    : STATUSES.NONE;

  return { isLoggedIn, isSubscribed, status };
};

export default useAccountStatus;
