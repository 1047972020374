import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast as ToastInner } from "react-bootstrap";

import { setToast } from "../../redux/actions/active";

const Toast = () => {
  const toast = useSelector(state => state.active?.toast);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setToast(null));
  };

  return (
    <ToastInner
      onClose={onClose}
      show={Boolean(toast)}
      delay={3000}
      autohide
      style={{ position: "absolute", top: 80, right: 24 }}
    >
      <ToastInner.Body>
        <strong className="mr-auto">{toast}</strong>
      </ToastInner.Body>
    </ToastInner>
  );
};

export default Toast;
