import React from "react";
import styled from "styled-components";
import firebase from "../../utils/firebase";
import googleImg from "../../assets/images/google-signin-button.png";

const Container = styled.div`
  > * {
    margin-bottom: 12px;
  }
`;

const Title = styled.div`
  font-size: 24px;
`;

const LoginButton = styled.div`
  background-image: url(${googleImg});
  background-size: contain;
  background-repeat: no-repeat;
  width: 70%;
  height: 50px;
  cursor: pointer;
`;

const LoginCard = () => {
  const onClick = () => {
    firebase.auth().signInWithRedirect(new firebase.auth.GoogleAuthProvider());
  };

  return (
    <Container>
      <Title>ログインしてコードを実行しよう！</Title>
      <LoginButton onClick={onClick} />
    </Container>
  );
};

export default LoginCard;
