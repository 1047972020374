import React, { useEffect, useCallback, useState } from "react";
import Collapse from "react-bootstrap/Collapse";

import { Container, Section } from "./components";
import useCurrentArticle from "../../hooks/useCurrentArticle";
import useActions from "../../hooks/useActions";

const Article = () => {
  const { articleId, article, sections } = useCurrentArticle();
  const { getSections, getArticle } = useActions();
  const [currentSection, setCurrentSection] = useState(0);

  const getData = useCallback(async () => {
    await getArticle(String(articleId));
    getSections(String(articleId));
  }, [getArticle, getSections, articleId]);

  const onClickCollapse = () => {
    setCurrentSection(current => current + 1);
  };

  const shouldShowButtons = index => {
    // Only when it's the last in the visible sections, and not the last section in the all sections.
    return index === currentSection && index < sections.length - 1;
  };

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      <h1>{article?.title}</h1>
      <div>
        {sections.map((section, index) => (
          <Collapse in={index <= currentSection} key={section.id}>
            <div>
              <Section
                section={section}
                key={section.id}
                onClickCollapse={shouldShowButtons(index) && onClickCollapse}
              />
            </div>
          </Collapse>
        ))}
      </div>
    </Container>
  );
};

export default Article;
