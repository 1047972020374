import React from "react";
import styled from "styled-components";
import FA from "react-fontawesome";

export const StyledRow = styled.div`
  display: flex;
  > * {
    margin-right: 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Console = styled.div`
  background-color: #333333;
  color: white;
  flex: 1;
  padding: 20px;
`;

export const ResultsContainer = styled.div`
  flex: 1;
  > * {
    margin-top: 10px;
  }
`;

const TestResultContainer = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
`;

const TestResult = ({ description, result }) => {
  const mark = result ? (
    <FA name="check-circle" style={{ color: "#14dfcd" }} />
  ) : (
    <FA name="times-circle" style={{ color: "red" }} />
  );

  return (
    <TestResultContainer>
      {mark}
      <div>{description}</div>
    </TestResultContainer>
  );
};

export const TestResults = ({ testResults }) => {
  return (
    <div>
      <div>
        Test Results: {testResults.filter(tr => tr.result === true).length} /{" "}
        {testResults.length}
      </div>
      {testResults.map(result => (
        <TestResult
          key={result.description}
          description={result.description}
          result={result.result}
        />
      ))}
    </div>
  );
};
