import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import topImage from "../assets/images/top.png";
import kenta from "../assets/images/kenta.jpg";
import media from "../utils/media";
import tipCodingImage from "../assets/images/coding.png";
import tipCommunityImage from "../assets/images/community.png";
import tipQusetionImage from "../assets/images/level.png";
import Quote from "../components/Quote";

const TopImage = styled.img`
  width: 400px;

  ${media.sp`
    width: 320px;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding: 0 32px;
`;

const Title = styled.h1`
  text-align: center;
  margin-top: -8px;
  font-size: 56px;

  ${media.sp`
    font-size: 40px;
  `}
`;

const Description = styled.div`
  font-size: 24px;
  text-align: center;
`;

const CourseButton = styled(Button)`
  align-self: center;
  width: 320px;
  height: 48px;
  border-radius: 24px;
  font-weight: bold;
  margin-top: 40px;
`;

const Subtitle = styled.h2`
  text-align: center;
  margin-top: 56px;
`;

const Br = styled.br`
  display: none;

  ${media.sp`
    display: inline;
  `}
`;

const Tips = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  flex: 1;
  justify-content: space-between;

  ${media.sp`
    flex-direction: column;
    align-items: center;
  `}
`;

const TipContainer = styled.div`
  text-align: center;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  padding: 16px;
  width: 32%;

  ${media.sp`
    width: 88%;
    margin-bottom: 8px;
  `}
`;

const TipTitle = styled.h3`
  margin-bottom: 16px;
`;

const TipDescription = styled.div`
  text-align: left;
`;

const TipImage = styled.img`
  margin-top: 16px;
`;

const Tip = ({ img, title, description }) => {
  return (
    <TipContainer>
      <TipTitle>{title}</TipTitle>
      <TipDescription>{description}</TipDescription>
      <TipImage src={img} alt="tip" />
    </TipContainer>
  );
};

const tips = [
  {
    img: tipCodingImage,
    title: "すぐにチェック！",
    description: "コードエディターですぐに自分の書いたコードが確認できる"
  },
  {
    img: tipCommunityImage,
    title: "プロと情報交換！",
    description: "プライベートグループの永久アクセスでprofessionalと情報交換"
  },
  {
    img: tipQusetionImage,
    title: "良質な問題集！",
    description: "本物の面接に近い良質かつ多種多様な問題をご用意"
  }
];

const Recommends = styled.ul`
  margin-top: 24px;
  > * {
    margin-bottom: 16px;
  }
`;

const recommendList = [
  "アルゴリズムやコンピューターサイエンスの基礎を身につける場が欲しい",
  "コーディングについて本だけでは不十分、実際にコードを書き、簡単にテストケースが検証できる環境が欲しい",
  "コーディング問題を解くだけではなく、その思考過程やどんな対話をして問題解決するかを重点的に学びたい",
  "キャリア、技術に関する相談が気軽に出来る場が欲しい"
];

const Home = () => {
  const history = useHistory();

  const onClickFullCourse = () => {
    history.push("/articles");
  };

  return (
    <Container>
      <TopImage src={topImage} alt="top" />
      <Title>コーディング面接を朝飯前に！</Title>
      <Description>
        Code BreakFastで一皮むけて、希望額より年収+200万円へ！
      </Description>
      <CourseButton onClick={onClickFullCourse}>
        Go to Full Course →
      </CourseButton>
      <Subtitle>
        Code BreakFastが
        <Br />
        選ばれる理由
      </Subtitle>
      <Tips>
        {tips.map(tip => (
          <Tip key={tip.title} {...tip} />
        ))}
      </Tips>
      <Subtitle>こんな人にオススメ</Subtitle>
      <Recommends>
        {recommendList.map(recommend => (
          <li key={recommend}>{recommend}</li>
        ))}
      </Recommends>
      <Quote
        name="Kenta"
        company="Hoge"
        message="I can't believe how this problem set covered such a wide variety of algorithmic approaches in detail. It transformed the way I think about interviews and gave me immense confidence."
        img={kenta}
      />
      <CourseButton onClick={onClickFullCourse}>
        Go to Full Course →
      </CourseButton>
    </Container>
  );
};

export default Home;
