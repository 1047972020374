import { GET_ARTICLES, GET_ARTICLE } from "../actions/types";

const articles = (state = {}, { type, payload }) => {
  if (type === GET_ARTICLES + "_SUCCESS" && payload?.articles) {
    return payload?.articles || {};
  }

  if (type === GET_ARTICLE + "_SUCCESS" && payload?.article) {
    return { ...state, [payload?.article?.id]: payload?.article };
  }

  return state;
};

export default articles;
