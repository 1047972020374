import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { LinkContainer } from "react-router-bootstrap";
import Highlight from "react-highlight";
import "../../../node_modules/highlight.js/styles/github.css";
import { Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import LoginCard from "../../components/LoginCard";
import useAccountStatus from "../../hooks/useAccountStatus";
import { useActions } from "../../hooks";

const CodeBlock = ({ value }) => <Highlight>{value}</Highlight>;

const SubscriptionContainer = styled.div`
  border-radius: 20px;
  box-shadow: 0px 0px 22px rgba(51, 51, 51, 0.3);
  padding: 24px 30px;
  text-align: center;
  max-width: 450px;
  margin: 30px auto;

  > * {
    margin-bottom: 15px;
  }
`;

const SubscriptionTitle = styled.h3`
  font-size: 24px;
`;

const RoundedButton = styled(Button)`
  border-radius: 22px;
  height: 44px;
  padding: 8px 24px;
`;

const ButtonsContainer = styled(Row)`
  justify-content: center;
  margin-top: 40px;
  > * {
    margin-right: 16px;
  }
`;

const SectionContainer = styled.div`
  margin-bottom: 24px;
`;

const UpgradeCard = () => {
  return (
    <SubscriptionContainer>
      <SubscriptionTitle>
        You've already used up your free full questions!
      </SubscriptionTitle>
      <div>
        Upgrade to see the full solution and breakdown for all our questions.
      </div>
      <LinkContainer to="/subscription">
        <RoundedButton>upgrade now →</RoundedButton>
      </LinkContainer>
    </SubscriptionContainer>
  );
};

const Content = ({ section, onClickCollapse }) => {
  const openCodeEditor = useSelector(state => state.active?.openCodeEditor);
  const { setOpenCodeEditor } = useActions();

  if (section.type === "md") {
    const onClickCodeButton = () => {
      setOpenCodeEditor(!openCodeEditor);
    };
    return (
      <SectionContainer>
        <ReactMarkdown
          source={section.content}
          escapeHtml={false}
          renderers={{ code: CodeBlock }}
          className="cb-markdown"
        />
        {onClickCollapse && (
          <ButtonsContainer>
            <RoundedButton onClick={onClickCollapse}>次へ↓</RoundedButton>
            <RoundedButton onClick={onClickCodeButton}>
              コードを書く
            </RoundedButton>
          </ButtonsContainer>
        )}
      </SectionContainer>
    );
  }

  return <div>This is not md</div>;
};

export const Container = styled.div`
  > * {
    margin-bottom: 40px;
  }
`;

export const Section = ({ section, onClickCollapse = null }) => {
  const { isLoggedIn, isSubscribed } = useAccountStatus();

  if (section.needSubscription) {
    if (!isLoggedIn) {
      return (
        <SubscriptionContainer>
          <LoginCard />
        </SubscriptionContainer>
      );
    }

    if (!isSubscribed) {
      return <UpgradeCard />;
    }
  }

  return <Content section={section} onClickCollapse={onClickCollapse} />;
};
