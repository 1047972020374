import { GET_ARTICLES, GET_ARTICLE } from "./types";
import { db } from "../../utils/firebase";

export const getArticles = () => async (dispatch, state) => {
  dispatch({ type: GET_ARTICLES + "_REQUEST" });

  try {
    const res = await db.collection("articles").get();
    const articles = res.docs.reduce((o, doc) => {
      o[doc.id] = { ...doc.data(), id: doc.id };
      return o;
    }, {});
    dispatch({ type: GET_ARTICLES + "_SUCCESS", payload: { articles } });
  } catch (error) {
    dispatch({ type: GET_ARTICLES + "_FAILURE", payload: { error } });
  }
};

export const getArticle = articleId => async (dispatch, state) => {
  dispatch({ type: GET_ARTICLE + "_REQUEST" });

  try {
    const res = await db
      .collection("articles")
      .doc(articleId.toString())
      .get();
    console.log("res", res.data(), res.id);
    const article = { ...res.data(), id: res.id };
    dispatch({ type: GET_ARTICLE + "_SUCCESS", payload: { article } });
  } catch (error) {
    console.log("e", error, JSON.stringify(error));
    dispatch({ type: GET_ARTICLE + "_FAILURE", payload: { error } });
  }
};
