import { POST_SOLUTION } from "./types";
import firebase from "../../utils/firebase";

export const postSolution = ({ articleId, solution }) => async (
  dispatch,
  getState
) => {
  const languageId = getState().active?.languageId;
  const userId = getState().account?.id;
  dispatch({
    type: POST_SOLUTION + "_REQUEST",
    payload: { languageId, articleId, solution, userId }
  });

  try {
    const checkSolution = firebase.functions().httpsCallable("checkSolution");
    const response = await checkSolution({
      articleId,
      languageId,
      solution,
      userId
    });
    const testResults = response.data;
    dispatch({
      type: POST_SOLUTION + "_SUCCESS",
      payload: { testResults, articleId, solution }
    });

    return testResults;
  } catch (error) {
    console.log("erro", error, `${error}`);
    dispatch({ type: POST_SOLUTION + "_FAILURE", payload: { error } });
  }
};
